<script>
  import LoginBox from "~/components/LoginBox.svelte";
  import { i18n } from "~/common/i18n.js";
  import ButtonBar from "~/components/ButtonBar.svelte";
  import Button from "~/components/Button.svelte";

  let { errorContext, siteName, siteDomain } = $props();

  let siteBaseUrl = `https://${siteDomain}`;
</script>

<LoginBox title={$i18n.t('login-error.genericTitle')}>
  {#if errorContext === 'cookies-disabled-on-site'}
    <p>{$i18n.t('login-error.cookies-disabled-on-site', { siteName, siteDomain })}</p>
  {:else}
    <p>{$i18n.t('login-error.genericMessage')}</p>
  {/if}
  <p class="reason">{$i18n.t('login-error.reason', { reason: errorContext })}</p>
  <ButtonBar>
    <Button prio="true" href={siteBaseUrl}>Tilbake til {siteName}</Button>
  </ButtonBar>
</LoginBox>

<style>
    .reason {
        color: var(--darkGray);
    }
</style>